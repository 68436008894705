import React from 'react';
import { useState} from 'react';
import { Container, Row} from 'react-bootstrap';
import {initTheme} from '../../utils/theme'
import i18n from '../../language/i18';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import Footer from '../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../redux/settingsReducer';
import './Pricing.css';
import '../landing/Landing.css';
import PricingTables from './PricingTables';
import LandingHeader from '../landing/LandingHeader';
import Header from '../header/Header'
import Countdown from '../landing/Countdown';
const Pricing = () => {
 // code for initializing the theme
  initTheme()
  // selector to get user from redux store
  const user = useSelector((state) => state.user);
  // code to get settings values from redux
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const setSettings1 = (res)=>{dispatch(setSettings(res))}
  const [language, setLanguage] = useState(settings.language);
  // hook for translation
  const { i18n, t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
    setSettings1({...settings, language: language})
  }, [language]);
  // google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  //data for the comparaison table
  const indexData = [
    { region: 'NNN',country: 'USA',index: 'DOWJONES',free: true,premium: true,pro: true },
    { region: 'NNN',country: 'USA',index: 'NASDAQ100',free: true,premium: true,pro: true },
    { region: 'NNN',country: 'USA',index: 'SP500',free: false,premium: true,pro: true },
    { region: 'NNN',country: 'CAN',index: 'TSX60',free: false,premium: true,pro: true },
    { region: 'NNN',country: 'MEX',index: 'BMVIPC',free: false,premium: true,pro: true },
    { region: 'EU',country: '',index: 'EUROSTOXX50',free: true,premium: true,pro: true },
    { region: 'EU',country: 'ESP',index: 'IBEX35',free: true,premium: true,pro: true },
    { region: 'EU',country: 'FRA',index: 'CAC40',free: true,premium: true,pro: true },
    { region: 'EU',country: 'DEU',index: 'DAX40',free: true,premium: true,pro: true },
    { region: 'EU',country: 'SWE',index: 'OMXS30',free: false,premium: true,pro: true },
    { region: 'EU',country: 'ITA',index: 'FTSEMIB',free: false,premium: true,pro: true },
    { region: 'EU',country: 'PRT',index: 'PSI20',free: false,premium: true,pro: true },
    { region: 'EU',country: 'NLD',index: 'AEX25',free: false,premium: true,pro: true },
    { region: 'EU',country: 'BEL',index: 'BEL20',free: false,premium: true,pro: true },
    { region: 'EU',country: 'IRL',index: 'ISEQ20',free: false,premium: false,pro: true },
    { region: 'EU',country: 'DNK',index: 'OMXC25',free: false,premium: false,pro: true },
    { region: 'EU',country: 'FIN',index: 'OMXH25',free: false,premium: false,pro: true },
    { region: 'EEE',country: 'CHE',index: 'SMI',free: true,premium: true,pro: true },
    { region: 'EEE',country: 'GBR',index: 'FTSE100',free: false,premium: true,pro: true },
    { region: 'EEE',country: 'NOR',index: 'OBXO25',free: false,premium: false,pro: true },
    { region: 'ABB',country: 'JPN',index: 'NIKKEI225',free: true,premium: true,pro: true },
    { region: 'ABB',country: 'KOR',index: 'KOSPI',free: false,premium: true,pro: true },
    { region: 'ABB',country: 'IND',index: 'NIFTY50',free: false,premium: true,pro: true },
    { region: 'ABB',country: 'HKG',index: 'HSI50',free: false,premium: false,pro: true },
    { region: 'ABB',country: 'SGP',index: 'STI',free: false,premium: false,pro: true },
    { region: 'UUU',country: 'AUS',index: 'ASX50',free: false,premium: false,pro: true },
    { region: 'SRR',country: 'ARG',index: 'MERVAL',free: true,premium: true,pro: true },
    { region: 'SRR',country: 'BRA',index: 'IBOVESPA',free: false,premium: true,pro: true },
    { region: 'SRR',country: 'CHL',index: 'CLXIPSA',free: false,premium: false,pro: true },
    { region: 'FFF',country: 'ZAF',index: 'SA40',free: false,premium: false,pro: true }
  ];
   
    
  const groupedIndexData = indexData.reduce((acc, curr) => {
    const { region } = curr;
    if (!acc[region]) {
      acc[region] = [];
    }
    acc[region].push(curr);
    return acc;
  }, {});

    return (
  <div>
    {
      user & user.user ? 
      <Header handleShow={0}></Header>
      :
      <LandingHeader></LandingHeader>
    }
     
        <Container className='mt-5'>
         
          <Row>
            <div className="pt-3 title-big mb-3 text-center" >
              <span>{t('pricing.title')}</span>
              <span className='title-attention'>{t('pricing.title2')}</span>
            </div>
            
          </Row>
         {/*  <Row>
            <div className='text text-center mb-3 mt-lg-5 mb-lg-5'>
              {t('landing.pricing.subtitle')}
            </div>
            <Countdown/>
          </Row> */}

           <PricingTables></PricingTables>

          <Container id='indexPricingTable' className='d-flex justify-content-center mb-5'>
            <table className='mt-5  tbl-container'>
              <thead className='tbl-header'>
                <tr>
                  <th>{t('pricing.region')}</th>
                  <th>{t('pricing.country')}</th>
                  <th>{t('pricing.index')}</th>
                  <th className='text-center'>Free</th>
                  <th className='text-center'>Premium</th>
                  <th className='text-center'>Pro</th>
                </tr>
              </thead>
              <tbody>
                  {Object.keys(groupedIndexData).map((region, regionIndex) => {
                    const regionData = groupedIndexData[region];
                    return regionData.map((data, index) => (
                      <tr
                        key={`${region}-${index}`}
                        onMouseEnter={() => {
                          // Highlight the region cell on hover
                          const regionCell = document.getElementById(`region-${regionIndex}`);
                          if (regionCell) regionCell.classList.add('region-highlight');
                        }}
                        onMouseLeave={() => {
                          // Remove the highlight on mouse leave
                          const regionCell = document.getElementById(`region-${regionIndex}`);
                          if (regionCell) regionCell.classList.remove('region-highlight');
                        }}
                      >
                        {index === 0 && (
                          <td
                            id={`region-${regionIndex}`}
                            rowSpan={regionData.length}
                            className="region-cell"
                          >
                            {t('data.continent.' + region)}
                          </td>
                        )}
                        <td>{data.country === '' ? '' : t('data.country.' + data.country)}</td>
                        <td>{t('data.indexes.' + data.index)}</td>
                        <td className="text-center fw-bolder">
                          {data.free ? <div style={{ color:'#22cfa1'}}>✔</div> : <div style={{ color:'#6d7986'}}>✖</div>}
                        </td>
                        <td className="text-center fw-bolder">
                          {data.premium ? <div style={{ color:'#22cfa1' }}>✔</div> :<div style={{ color:'#6d7986'}}>✖</div>}
                        </td>
                        <td className="text-center fw-bolder">
                          {data.pro ? <div style={{ color:'#22cfa1' }}>✔</div> : <div style={{ color:'#6d7986'}}>✖</div>}
                        </td>
                      </tr>
                    ));
                  })}
                </tbody>

            </table>
          </Container>
        </Container>
      <Footer className="mt-5"></Footer>
  </div>
    );
  };

export default Pricing;