import { createSlice } from '@reduxjs/toolkit';
function getSettings(){
  const stored= JSON.parse(localStorage.getItem('settings'));
  //default setings
  const userLanguage = navigator.language || navigator.userLanguage;
  const getLanguage= ()=>{
    if ('es-ES'===navigator.language || navigator.userLanguage){
      //return 'es'
      return 'en' // i change this from 'es' to 'en' so the default language is always english to avoid problems
    }else{
      return 'en'
    }
  }
  const defaultSettings = {
                            graph:{color:'redAndGreen'},
                            introModal:{show : true},
                            language:getLanguage(),
                          };
  if (stored) {
    var aKeys = Object.keys(stored).sort();
    var bKeys = Object.keys(defaultSettings).sort();
    if (JSON.stringify(aKeys) === JSON.stringify(bKeys)){
      return stored;
    } else{
      return defaultSettings
    }
      
  } else {
    return defaultSettings
  }
}
const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    settings: getSettings(),
  },
  reducers: {
    setSettings: {
      reducer: (state, action) => {
        state.settings = action.payload;
        localStorage.setItem("settings", JSON.stringify(action.payload));
      }
    },
  },
});


export const { setSettings } = settingsSlice.actions;
export default settingsSlice.reducer;