import React from 'react';
import './Landing.css';
import { Container, Row, Col } from 'react-bootstrap';
import { landingExampleData} from '../../utils/landingExampleData';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Graphline from '../todaysignals/Graphline';
const TryIt = () => {
  // code to get settings values from redux
  const settings = useSelector((state) => state.settings.settings);
  // hook for translation
  const { i18n, t } = useTranslation();
return (
<div className='black-background text-white'>
  <Container className='padding-section '>
    <Row className='align-items-center text-center'>
        <div className='title-big'>
          {t('landing.try_it.title')}
        </div>
        <div className='text mt-4 mb-4'>
          {t('landing.try_it.text_up_signal')}
        </div>
    </Row>
    <Container className='px-2 bg-white text-dark rounded-3 img-shadows pt-3 pb-3 pt-lg-4 pb-lg-4'>
      <Row className=' mb-2 mx-1 d-none d-lg-flex'>
        <Col xs={2} lg={1}>
        </Col>
              <Col xs={8} lg={10}>
                  <Container fluid >
                      <Row>
                          <Col lg={3} className='text-start h5 px-0'>
                          {t('tablah.company')}
                          </Col>
                          <Col lg={2} className='text-start h5 px-0'>
                          {t('tablah.strategy')}
                          </Col>
                          <Col lg={2} className='text-start h5 px-0'>
                          {t('tablah.position')}
                          </Col>
                          <Col lg={2} className='d-none d-lg-block text-start h5 px-0'>
                          {t('tablah.lastclosing')}
                          </Col>
                          <Col lg={3} className='d-none d-lg-block text-start h5 px-0'>
                          {t('tablah.sector')}
                          </Col>
                      </Row>
                  </Container>
              </Col>
              <Col lg={1}>
              </Col>
      </Row>
      <Graphline className="callAttention" id = {1} line = {landingExampleData} settings={settings}></Graphline>
    </Container>
    <Row className='align-items-center text-center mt-2'>
      <div className='text mt-4'>
      {t('landing.try_it.text_down_signal')}
      </div>
    </Row>
  </Container>
</div>
);
};

export default TryIt;