export const landingArrays = [
    //1
  {  version:1,
      array:[
      {section:'hero', option:3 ,cta:2,},
      {section:'critique', option:1},
      {section:'solution', option:0 ,dark: false},
      {section:'how_it_works', option:1, dark: true},
      {section:'try_it'},     
      {section:'social_proof'}, 
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
    ]
  },
    //2
    {  version:2,
      array:[
      {section:'hero', option:2 ,cta:2,},
      {section:'solution', option:1 ,dark: true},     
      {section: 'features', option:0, dark: false}, 
      {section:'comparison'},
      {section:'social_proof'}, 
      {section:'how_it_works', option:1, dark: true},
      {section:'try_it'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
    ]},
    //3
    {  version:3,
      array:[
      {section:'hero', option:0 ,cta:1,},
      {section:'critique', option:0},
      {section:'solution', option:0 ,dark: false},
      {section:'how_it_works', option:1, dark: true},
      {section:'try_it'},
      {section:'social_proof'},      
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]},
      //4
      {  version:4,
        array:[
      {section:'hero', option:0 ,cta:1,},
      {section:'critique', option:2},
      {section:'solution', option:2 ,dark: false},
      {section:'how_it_works', option:0, dark: true},
      {section:'try_it'},    
      {section:'social_proof'},  
      {section: 'features', option:0, dark: false}, 
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]}, 
  //5
  {  version:5,
    array:[
      {section:'hero', option:1 ,cta:0,},
      {section:'solution', option:2 ,dark: true},
      {section:'how_it_works', option:1, dark: false},
      {section:'try_it'},
      {section:'social_proof'},      
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]},
   //6
   {  version:6,
    array:[
      {section:'hero', option:1 ,cta:0,},
      {section: 'features', option:0, dark: true},
      {section:'solution', option:2 ,dark: false},
      {section:'how_it_works', option:0, dark: true},
      {section:'try_it'},
      {section:'social_proof'},      
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:0},
  ]},
  //7

  {  version:7,
    array:[
      {section:'hero', option:3 ,cta:2,},
      {section:'critique', option:2},
      {section:'solution', option:2 ,dark: false},
      {section:'how_it_works', option:1, dark: true},
      {section:'try_it'},     
      {section:'social_proof'}, 
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]},
//8
{  version:8,
  array:[
      {section:'hero', option:3 ,cta:2,},
      {section: 'features', option:0, dark: true},
      {section:'solution', option:2 ,dark: false},
      {section:'how_it_works', option:0, dark: true},
      {section:'try_it'},
      {section:'social_proof'},      
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:0},
  ]},
  //9
  {  version:9,
    array:[
      {section:'hero', option:2 ,cta:2,},
      {section:'critique', option:2},
      {section:'solution', option:2 ,dark: false},
      {section:'how_it_works', option:1, dark: true},
      {section:'try_it'},     
      {section:'social_proof'}, 
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]
  },
  //10

  {  version:10,
    array:[
      {section:'hero', option:2 ,cta:2,},
      {section:'solution', option:2 ,dark: true},
      {section:'how_it_works', option:0, dark: false},
      {section:'try_it'},
      {section:'social_proof'},  
      {section: 'features', option:0, dark: false},    
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:0},
  ]
  },
    //11

    {  version:11,
      array:[
        {section:'hero', option:4 ,cta:2,},
        {section:'solution', option:2 ,dark: true},
        {section:'how_it_works', option:0, dark: false},
        {section:'try_it'},
        {section:'social_proof'},  
        {section: 'features', option:0, dark: false},    
        {section:'comparison'},
        {section:'pricing'},
        {section:'faq'},
        {section:'last_call_to_action', option:0, cta:0},
    ]
    },
      //12
  {  version:12,
    array:[
      {section:'hero', option:4 ,cta:2,},
      {section:'critique', option:2},
      {section:'solution', option:2 ,dark: false},
      {section:'how_it_works', option:1, dark: true},
      {section:'try_it'},     
      {section:'social_proof'}, 
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]
  },
  {  version:13,
    array:[
      {section:'hero', option:0 ,cta:0,},
      {section:'solution', option:3 ,dark: true},
      {section:'how_it_works', option:1, dark: false},
      {section:'try_it'},     
      {section:'social_proof'}, 
      {section: 'features', option:0, dark: false},
      {section:'comparison'},
      {section:'pricing'},
      {section:'faq'},
      {section:'last_call_to_action', option:0, cta:1},
  ]
  },
    

  ]