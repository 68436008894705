import React from 'react';
import './Landing.css';
import { Container} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PricingTables from '../pricing/PricingTables';
import Countdown from './Countdown';
const PricingLandingSection = () => {
  // hook for translation
  const { i18n, t } = useTranslation();
return (
<div className='black-background text-white'>
  <Container className='padding-section'>
    
    <div className='title-big text-center mb-3'>
      {t('landing.pricing.title')}
    </div>
    {/*<div className='text text-center mb-3'>
      {t('landing.pricing.subtitle')}
      <b style={{fontWeight: '800' }}>{t('landing.pricing.subtitle2')}</b>
    </div>
     <Countdown/> */}
    <PricingTables></PricingTables>
  </Container>
</div>
);
};

export default PricingLandingSection;